import { createApp, h } from 'vue'
import App from './App.vue'
import store from './store'
import VueCookies from 'vue-cookies'


const setupApp = (element, data) => {
    console.log(data);
    createApp({
        created() {
            this.$store.dispatch('initApp', data);
        },
        render: () => h(App)
    }).use(store).use(VueCookies, { expires: '1m'}).mount(element);
}

if(process.env.NODE_ENV === 'production') {
    window.CURTAIN = window.CURTAIN || setupApp
} else {
    const appElement = document.querySelector('#app');
    if(appElement) {
        const mockData = {
            text: 'Mock text'
        }
        setupApp(appElement, mockData);
    }
}
