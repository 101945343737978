const setStateMutation = (state, payload) => {
    Object.assign(state, payload);
}
const setShowTimerMutation = (state, payload) => {
    state.showTimer = payload;
}
const mutations = {
    setState: setStateMutation,
    setShowTimer: setShowTimerMutation
}

export default mutations;