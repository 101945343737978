<template>
  <focus-trap :active="focused">
    
    <div v-if="!hasVisited" :class="{ slmanimatecurtain: animate }" class="slm-curtains__container">
        <div v-html="getCurtainSvg" class="slm-curtains__curtain"></div>
        <div class="slm-curtains__content">
          <h1>Välkommen!</h1>
          <p v-if="showTimer" class="slm-curtains__intro-text">{{textNotPublished}}</p>
          <p v-if="!showTimer" class="slm-curtains__intro-text">{{textPublished}}</p>
          <div v-if="showTimer">
            <Countdown :deadline="timerDate" :labels="labels"/>
          </div>
          <button v-if="!showTimer" class="slm-link-button slm-curtains__button" @click="setHasVisited">Gå till hemsidan</button>
        </div>
    </div>
  </focus-trap>
</template>

<script>
import {Countdown} from 'vue3-flip-countdown';
import { FocusTrap } from 'focus-trap-vue'
export default {
  name: "App",
  components: {
    Countdown,
    FocusTrap
  },
  data() {
    return{
      hasVisited: false,
      animate: false,
      focused: false,
      currentTime: 0,
    }
  },
  watch: {
    currentTime(newVal){
      let deadline = Date.parse(this.dateTime);
    console.log(this.dateTime);
      console.log(deadline <= newVal);
      console.log(deadline, "  ", newVal);
      if(deadline <= newVal)
      {
        this.$store.commit("setShowTimer", false);
      }
    },
  },
  computed: {
    getCurtainSvg() {
      return this.$store.state.svg;
    },
    textNotPublished() {
      return this.$store.state.textNotPublished;
    },
    textPublished() {
      return this.$store.state.textPublished;
    },
    timerDate() {
      return this.$store.state.timerDate;
    },
    showTimer() {
      return this.$store.state.showTimer;
    },
    dateTime(){
      return this.$store.state.data;
    },
    labels() {
      return {days: 'Dagar',hours: 'Timmar',minutes: 'Minuter',seconds: 'Sekunder'}
    },
  },
  methods: {
    isActiveFocus () {
      this.focused = !this.focused;
    },
    setHasVisited () {
      this.animate = true;
      this.focused = false;
      this.$cookies.set("slm_curtain_has_been_clicked", "used", '1m')
    }
  },
  mounted(){
    this.focused = true;
    window.setInterval(() => {
      this.currentTime = new Date().getTime()
    }, 1000)
  }
};
</script>